import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import WrapHeightContext from "shared/context/WrapHeightContext";

import styles from "./ErrorBoundary.module.scss";

import errorImg from "assets/shared/img/ic_something_wrong.svg";

interface ErrorBoundaryContentProps {
  text?: boolean;
  customClass?: string | null;
  withReload?: boolean;
}

export const ErrorBoundaryContent: React.FC<ErrorBoundaryContentProps> = ({
  text = true,
  customClass = null,
  withReload = true,
}) => {
  const { t } = useTranslation();
  const minWrapHeight = useContext(WrapHeightContext);

  return (
    <div
      className={`${styles.errorBoundaryWrap} ${customClass ?? ""}`}
      style={
        minWrapHeight?.minHeight
          ? { minHeight: `${minWrapHeight.minHeight}px` }
          : undefined
      }
    >
      <img className={styles.icon} src={errorImg} alt={"error-icon"} />
      <h2 className={styles.title}>{t("common:error.boundary.title")}</h2>
      {text && <p className={styles.text}>{t("common:error.boundary.text")}</p>}
      {!withReload ? (
        <NavLink to={"/app/"} className={styles.button}>
          {t("common:error.boundary.button")}
        </NavLink>
      ) : (
        <a href={"/app/"} className={styles.button}>
          {t("common:error.boundary.button")}
        </a>
      )}
    </div>
  );
};
