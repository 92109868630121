import React from "react";
import { connect } from "react-redux";
import axios from "axios";

import { sentryInstance } from "shared/helpers/utils";

import { ErrorBoundaryContent } from "./ErrorBoundaryContent";

type Profile = {
  uid?: string | number;
};

type ErrorBoundaryProps = {
  profile?: Profile;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const message = {
      message: error.toString(),
      stack: error.stack || "",
      location: window.location.href,
      domain: window.location.host,
      ua: window.navigator?.userAgent || "",
      uid: this.props?.profile?.uid || 0, // Default to 0 if no profile
      errorInfo: errorInfo.componentStack || "",
    };

    // Log error to Sentry
    if (sentryInstance) {
      sentryInstance.captureException(error, {
        extra: message,
      });
    }

    // Log error to server
    axios
      .post("/app-log", message)
      .catch((error) => console.error(error.toString()));
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryContent />;
    }
    return this.props.children;
  }
}

const mapStateToProps = (state: { profile?: Profile }) => {
  return { profile: state.profile };
};

const ReduxConnectedErrorBoundary = connect(mapStateToProps)(ErrorBoundary);

// Default export for Redux-connected components
export { ReduxConnectedErrorBoundary as ErrorBoundary };
// Export for non-Redux components
export { ErrorBoundary as UnconnectedErrorBoundary };
