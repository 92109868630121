import type * as SentryType from "@sentry/react";
import type { Scope } from "@sentry/core";
import type { AxiosError } from "axios";

import { isTrue } from "./view.js";

export let sentryInstance: typeof SentryType | null = null;

export async function initSentry(): Promise<void> {
  if (!sentryInstance && isTrue(import.meta.env.VITE_APP_SENTRY)) {
    let sentry;

    try {
      sentry = await import("@sentry/react");

      sentry.init({
        dsn: `https://${import.meta.env.VITE_APP_SENTRY_DSN}.ingest.sentry.io/${import.meta.env.VITE_APP_SENTRY_PROJECT_ID}`,
        environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
      });
    } catch (error) {
      console.error("Failed to import Sentry:", error);
    }

    sentryInstance = sentry;
  }
}

export function logErrorToSentry(error: AxiosError): void {
  if (sentryInstance) {
    sentryInstance.withScope((scope: Scope) => {
      scope.setTag("axios-error", error.response?.status || "unknown-status");
      sentryInstance.captureException(error);
    });
  }
}
