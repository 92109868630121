import { createContext } from "react";

export const WrapHeightContextDefault = {
  minHeight: null,
  needRecalculate: false,
};

/**
 * Create context for set min height of main wrap
 * @type {React.Context<{minHeight: null}>}
 */
const WrapHeightContext = createContext(WrapHeightContextDefault);

export default WrapHeightContext;
